// extracted by mini-css-extract-plugin
export var navbar = "SupportedBySection-module--navbar--yO0Cq";
export var navbarLight = "SupportedBySection-module--navbar-light--27AWq";
export var bgCompsoc = "SupportedBySection-module--bg-compsoc--1Hvrc";
export var navbarNav = "SupportedBySection-module--navbar-nav--33qZ9";
export var navLink = "SupportedBySection-module--nav-link--14ua6";
export var hero = "SupportedBySection-module--hero--2VcxE";
export var btnGroup = "SupportedBySection-module--btn-group--31_B3";
export var btn = "SupportedBySection-module--btn--2ZeVF";
export var fullscreen = "SupportedBySection-module--fullscreen--3BkrU";
export var full = "SupportedBySection-module--full--11b_R";
export var half = "SupportedBySection-module--half--1ow3S";
export var dark = "SupportedBySection-module--dark--39wkY";
export var orange = "SupportedBySection-module--orange--3VKtK";
export var sectionTag = "SupportedBySection-module--section-tag--1piBo";
export var link = "SupportedBySection-module--link--BBnQc";
export var disabled = "SupportedBySection-module--disabled--t_IT3";
export var media = "SupportedBySection-module--media--QCiWa";
export var news = "SupportedBySection-module--news--3E21a";
export var description = "SupportedBySection-module--description--1ZmEN";
export var links = "SupportedBySection-module--links--3LLK9";
export var events = "SupportedBySection-module--events--uZEjE";
export var event = "SupportedBySection-module--event--3PDw5";
export var item = "SupportedBySection-module--item--y2KT9";
export var box = "SupportedBySection-module--box--1d4Ks";
export var itemBody = "SupportedBySection-module--item-body--3msLL";
export var icon = "SupportedBySection-module--icon--2Eubb";
export var name = "SupportedBySection-module--name--3cUFr";
export var WhatWeDo = "SupportedBySection-module--WhatWeDo--3vvgm";
export var features = "SupportedBySection-module--features--g1DAG";
export var team = "SupportedBySection-module--team--1MgqK";
export var card = "SupportedBySection-module--card--3dMkf";
export var cardContent = "SupportedBySection-module--card-content--1G9QB";
export var cardPicture = "SupportedBySection-module--card-picture--1ZW9b";
export var picture = "SupportedBySection-module--picture--3RbF2";
export var footerText = "SupportedBySection-module--footer-text--2elIA";
export var details = "SupportedBySection-module--details--20q7J";